<template>
  <div class="main-container">
    <v-toolbar
        flat
        tile
        class="box"
        style="background-color:transparent"
    >
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-toolbar-title class="pl-0">
        เพิ่มข้อมูลการเติมน้ำมัน
      </v-toolbar-title>
      <v-spacer/>
    </v-toolbar>
    <div class="mx-4 pb-4 box box-2" style="min-height: 100%;">
      <v-card height="100%" class="px-2 pt-2" style="overflow: auto">
        <v-col
            cols="12"
            md="10"
            lg="8"
            xl="6"
            style="height: 100%"
        >
          <v-form
              ref="form"
              v-model="valid"
              lazy-validation
          >
            <v-row>
              <v-col cols="4" align-self="center">
                เลือกรูปแบบ
              </v-col>
              <v-col>
                <v-select
                    outlined
                    dense
                    height="32px"
                    class="rounded"
                    v-model="remark"
                    :items="remarkOption"
                    item-text="text"
                    item-value="value"
                    :rules="[rules.required_select]"
                    hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-row v-if="remark === 'เติมน้ำมัน'">
              <v-col cols="4" align-self="center">รถที่เติม</v-col>
              <v-col>
                <v-select
                    outlined
                    dense
                    height="32px"
                    class="rounded"
                    v-model="vehicle_id"
                    :items="vehicleOption"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required_select]"
                    hide-details="auto"
                    placeholder="เลือกรถ"
                >
                  <template slot="selection" slot-scope="data">
                    <div style="display: flex;width: 100%;justify-content: space-between">
                      <span>{{ data.item.name }}</span>
                      <span><span style="color: #265a88">ทะเบียน {{ data.item.licence }}</span></span>
                    </div>
                  </template>
                  <template slot="item" slot-scope="data">
                    <div style="display: flex;width: 100%;justify-content: space-between">
                      <span>{{ data.item.name }}</span>
                      <span><span style="color: #265a88">ทะเบียน {{ data.item.licence }}</span></span>
                    </div>
                  </template>
                </v-select>
              </v-col>
            </v-row>

            <v-row v-if="remark === 'เติมน้ำมัน'">
              <v-col cols="4" align-self="center">จำนวนลิตร</v-col>
              <v-col>
                <v-text-field outlined
                              :rules="[rules.required]"
                              v-model="liter"
                              suffix="ลิตร"
                              height="32px"
                              type="number"
                              hide-details="auto">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row v-if="remark === 'เติมน้ำมัน'">
              <v-col cols="4" align-self="center">ลิตรละ</v-col>
              <v-col>
                <v-text-field outlined
                              :rules="[rules.required]"
                              v-model="per_liter"
                              suffix="บาท"
                              height="32px"
                              type="number"
                              hide-details="auto">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" align-self="center">ราคา</v-col>
              <v-col>
                <v-text-field outlined
                              :rules="[rules.required]"
                              v-model="price"
                              suffix="บาท"
                              height="32px"
                              type="number"
                              hide-details="auto">
                </v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" align-self="center">วันที่</v-col>
              <v-col>
                <DatePicker v-model="date" default-today/>
              </v-col>
            </v-row>

            <v-row class="mt-6 mx-0">
              <v-btn :loading="isLoading" @click="toShowConfirm()" depressed color="success">
                <v-icon left>mdi-content-save</v-icon>
                บันทึกข้อมูล
              </v-btn>
            </v-row>
          </v-form>
        </v-col>
      </v-card>
    </div>

    <v-dialog persistent max-width="512" v-model="showConfirmDialog">
      <v-card>
        <v-card-title>ตรวจสอบข้อมูลการเติมน้ำมัน</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4" align-self="center">
              เลือกรูปแบบ
            </v-col>
            <v-col>
              <v-select
                  readonly
                  outlined
                  dense
                  height="32px"
                  class="rounded"
                  v-model="remark"
                  :items="remarkOption"
                  item-text="text"
                  item-value="value"
                  :rules="[rules.required_select]"
                  hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row v-if="remark === 'เติมน้ำมัน'">
            <v-col cols="4" align-self="center">รถที่เติม</v-col>
            <v-col>
              <v-select
                  readonly
                  outlined
                  dense
                  height="32px"
                  class="rounded"
                  v-model="vehicle_id"
                  :items="vehicleOption"
                  item-text="name"
                  item-value="id"
                  :rules="[rules.required_select]"
                  hide-details="auto"
                  placeholder="เลือกรถ"
              >
                <template slot="selection" slot-scope="data">
                  <div style="display: flex;width: 100%;justify-content: space-between">
                    <span>{{ data.item.name }}</span>
                    <span><span style="color: #265a88">ทะเบียน {{ data.item.licence }}</span></span>
                  </div>
                </template>
                <template slot="item" slot-scope="data">
                  <div style="display: flex;width: 100%;justify-content: space-between">
                    <span>{{ data.item.name }}</span>
                    <span><span style="color: #265a88">ทะเบียน {{ data.item.licence }}</span></span>
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>

          <v-row v-if="remark === 'เติมน้ำมัน'">
            <v-col cols="4" align-self="center">จำนวนลิตร</v-col>
            <v-col>
              <v-text-field outlined
                            readonly
                            :rules="[rules.required]"
                            v-model="liter"
                            suffix="ลิตร"
                            height="32px"
                            type="number"
                            hide-details="auto">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="remark === 'เติมน้ำมัน'">
            <v-col cols="4" align-self="center">ลิตรละ</v-col>
            <v-col>
              <v-text-field outlined
                            readonly
                            :rules="[rules.required]"
                            v-model="per_liter"
                            suffix="บาท"
                            height="32px"
                            type="number"
                            hide-details="auto">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" align-self="center">ราคา</v-col>
            <v-col>
              <v-text-field outlined
                            readonly
                            :rules="[rules.required]"
                            v-model="price"
                            suffix="บาท"
                            height="32px"
                            type="number"
                            hide-details="auto">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="4" align-self="center">วันที่</v-col>
            <v-col>
              <DatePicker disabled v-model="date" default-today/>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showConfirmDialog = false" elevation="0">ยังก่อน</v-btn>
          <v-spacer/>
          <v-btn @click="saveToDatabase()" elevation="0" color="success">ยืนยันการเพิ่มข้อมูล</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import DatePicker from "@/components/DatePicker";

export default {
  components: {DatePicker},
  name: "CreateOilTracker",
  data() {
    return {
      valid: false,
      rules: {
        required: value => !!value || "ต้องกรอก",
        required_select: value => !!value || "ต้องเลือก",
        min: value => value > 0 || "จำนวนต้องเป็นตั้งแต่ 1 ขึ้นไป"
      },
      vehicle_id: null,
      liter: 0,
      per_liter: 0,
      remark: "เติมน้ำมัน",
      price: 0,
      date: "",
      vehicleOption: [],
      remarkOption: [
        {
          text: 'เติมน้ำมัน',
          value: 'เติมน้ำมัน'
        },
        {
          text: 'เติมเงินคูปอง',
          value: 'เพิ่มคูปอง'
        }
      ],
      isLoading: false,
      showConfirmDialog: false
    }
  },
  created() {
    this.getVehicle()
  },
  methods: {
    getVehicle() {
      axios.get('/api/stocks/vehicle/')
          .then(res => {
            this.vehicleOption = res.data.data
          })
          .catch(e => {
            this.$store.state.snackbar = {
              text: 'เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง ข้อความที่ระบบแจ้ง : ' + e.response.status + ' ' + e.response.statusText,
              visible: true,
              color: 'error'
            }
          })
    },
    async toShowConfirm() {
      await this.$refs.form.validate()
      if (this.valid) {
        this.showConfirmDialog = true
      }
    },
    async saveToDatabase() {
      await this.$refs.form.validate()
      if (this.valid) {
        this.isLoading = true
        let payload = {
          vehicle_id: this.vehicle_id,
          liter: this.remark === 'เติมน้ำมัน' ? parseFloat(this.liter) : null,
          per_liter: this.remark === 'เติมน้ำมัน' ? parseFloat(this.per_liter) : null,
          remark: this.remark,
          price: parseFloat(this.price),
          date: this.date
        }
        console.log(payload)
        axios.post('/api/tracks/oil/', payload).then(() => {
          this.$store.state.snackbar = {
            text: 'เพิ่มข้อมูลการซ่อมบำรุงสำเร็จ',
            visible: true,
            color: 'success'
          }
          this.isLoading = false
          this.$router.push({path: '/oil-tracking'})
        })
      }

    }
  }
}
</script>

<style scoped>

</style>
